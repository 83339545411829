import React from 'react';
import brandColors from 'styles/skipper/brandColors';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { BANNER_TEXT } from './consts';

interface Props {
  onClose: () => void;
  trialDaysRemaining: number;
}

function FreeTrialBanner({ onClose, trialDaysRemaining }: Props) {
  const { classes } = useStyles();
  const over14DaysRemainingMessage = (
    <>
      There are <strong>{trialDaysRemaining} days</strong> left in your trial. Contact our sales
      team to purchase Sift or learn more.
    </>
  );

  return (
    <div className={classes.trialBanner}>
      <div className={classes.leftSide}>
        <div className={classes.bannerInfo}>
          <ErrorRoundedIcon className={classes.icon} />
          <span className={classes.bannerText}>
            {trialDaysRemaining > 14 ? over14DaysRemainingMessage : BANNER_TEXT[trialDaysRemaining]}
          </span>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.reachOut}
            href="https://www.justsift.com/book-a-time?utm_source=sift&utm_medium=banner&utm_campaign=free_trial_exp_banner"
          >
            reach out to sales
          </Button>
        </div>
      </div>
      <Divider orientation="vertical" flexItem className={classes.divider} variant="middle" />
      <div className={classes.closeContainer}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon className={classNames(classes.icon, classes.closeIcon)} />
        </IconButton>
      </div>
    </div>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    trialBanner: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 60,
      color: brandColors.common.white,
      backgroundColor: brandColors.secondaryPalette.cobalt,
      [theme.breakpoints.down('md')]: {
        minHeight: 106,
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: 147,
      },
    },
    leftSide: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    bannerInfo: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2.375),
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      },
    },
    bannerText: {
      [theme.breakpoints.down('sm')]: {
        maxWidth: 300,
      },
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      width: 'fit-content',
    },
    icon: {
      margin: theme.spacing(0, 1),
      color: brandColors.common.white,
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0, 3),
      },
    },
    reachOut: {
      minWidth: 164,
      marginBottom: 'auto',
      marginRight: theme.spacing(3),
    },
    divider: {
      borderColor: brandColors.secondaryPalette.sky,
    },
    closeContainer: {
      height: '100%',
      paddingTop: theme.spacing(2.375),
    },
    closeButton: {
      padding: 0,
      maxWidth: 24,
      margin: theme.spacing('auto', 3, 0, 3),
    },
    closeIcon: {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: 16,
      },
    },
  };
});

export default FreeTrialBanner;
