import React from 'react';

export const BANNER_TEXT = [
  <>
    <strong>Today's the day!</strong>&nbsp; Final hours of your trial—join us!
  </>,
  <>
    Last chance! &nbsp;<strong>1 day</strong>&nbsp; left in your trial. Get started now!
  </>,
  <>
    Hurry! Just &nbsp;<strong>2 days</strong>&nbsp; left in your trial. Sign up and thrive with us.
  </>,
  <>
    <strong>3 days</strong>&nbsp; left in your trial to discover what we offer. Click to begin.
  </>,
  <>
    <strong>4 days</strong>&nbsp; left in your trial. Don't delay, subscribe now!
  </>,
  <>
    <strong>5 days</strong>&nbsp; remaining in your trial. Start now to make the most of it.
  </>,
  <>
    Only &nbsp;<strong>6 days</strong>&nbsp; left in your trial. Maximize your trial by signing up
    today.
  </>,
  <>
    One week in! You still have &nbsp;<strong>7 days</strong>&nbsp; left in your trial to seize the
    opportunity.
  </>,
  <>
    <strong>8 days</strong>&nbsp; left to go in your trial. Unlock the full potential of Sift now!
  </>,
  <>
    Just &nbsp;<strong>9 days</strong>&nbsp; to go in your trial. Sign up today.
  </>,
  <>
    <strong>10 days</strong>&nbsp; left in your trial. Join us and elevate your productivity.
  </>,
  <>
    <strong>11 days</strong>&nbsp; remaining in your trial. Sign up now and supercharge your work.
  </>,
  <>
    <strong>12 days</strong>&nbsp; left in your trial to experience all our tools. Start today!
  </>,
  <>
    <strong>13 days</strong>&nbsp; left in your trial! Don't miss out on exploring our features.
  </>,
  <>
    You have &nbsp;<strong>14 days</strong>&nbsp; left in your trial. Begin your journey now!
  </>,
];
