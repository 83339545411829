import palette from './palette';
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import typography from 'styles/skipper/typography';
import skipperDefaultTheme from 'styles/skipper/skipperDefaultTheme';
import skipperDefaultPalette from 'styles/skipper/skipperDefaultPalette';

export default createTheme(
  deepmerge(skipperDefaultTheme, {
    presence: {
      online: '#26d368',
      busy: '#e12f04',
      away: '#ffd200',
      offline: '#b6ced2',
      unknown: '#b6ced2',
    },
    page: {
      width: '76rem',
      pageMaxWidth: 1280,
      navWidth: 250,
    },
    palette,
    customSpacing: {
      dialog: {
        sectionMargin: 24,
        sectionDividerMargin: 16,
      },
      profile: {
        position: 44,
        marginTop: 69,
      },
    },
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: skipperDefaultTheme.shadows[2],
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            boxShadow: skipperDefaultTheme.shadows[0],
          },
          text: {
            padding: '6px 12px',
          },
        },
        defaultProps: {
          color: 'inherit',
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          contained: {
            boxShadow: skipperDefaultTheme.shadows[0],
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: skipperDefaultTheme.shadows[2],
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: skipperDefaultPalette.text.primary,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 24,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '0 24px',
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            marginBottom: 0,
            ...skipperDefaultTheme.typography.body2,
            color: skipperDefaultPalette.text.gray1,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            ...typography.h5,
            padding: 24,
            '& span': {
              display: 'block',
              ...typography.body1,
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: skipperDefaultPalette.text.gray1,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: skipperDefaultPalette.text.gray1,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          secondary: {
            color: skipperDefaultPalette.text.gray1,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            boxShadow: skipperDefaultTheme.shadows[3],
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            ...typography.subtitle1,
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            '& caption': {
              ...typography.caption,
              color: skipperDefaultPalette.text.gray1,
            },
          },
        },
      },
    },
  }),
);
